.record[data-v-43b6c9e4] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.record .record_left[data-v-43b6c9e4] {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}
.record_left[data-v-43b6c9e4]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}